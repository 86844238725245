import React from 'react';
import Layout from '../components/Layout';
import TitleAndTheme from '../components/TitleAndTheme';
import ProjectHeader from '../components/ProjectHeader';
import Grid from '../components/Grid';
import AssetWithCaption from '../components/AssetWithCaption';
import Text from '../components/Text';

const XXX = () => (
  <Layout>
    <TitleAndTheme
      title="Edwin Morris, EDWIN.XXX personal project"
      themeClass="theme--xxx"
    />

    <ProjectHeader name="EDWIN.XXX" subhead="SFW" />

    <Text>
      <p>
        Looking for this? The XXX TLD sets expectations like no other TLD — this
        is my attempt at fulfilling them.
      </p>
      <p>
        I recently revisited this project after three years of no development.
        In 2014 I created the site with Middleman — it used Haml, CoffeeScript,
        and SCSS, and required a command line tool to output a few static files.
        I rewrote the site as a single index.html file with 150 lines, and
        reduced the video payload from ~7mb to under 1mb using H.264 — it even
        works on mobile now in case you need to watch on-the-go.
      </p>
    </Text>

    <Grid>
      <AssetWithCaption
        position="full"
        video="xxx/xxx"
        width="1400"
        height="602"
      >
        Are you?
      </AssetWithCaption>
    </Grid>

    <Text noBottomMargin>
      <p>
        Removing all the complexity I layered on in 2014 was a lesson in how
        quickly “best practices” and my own ideas about them move on.
      </p>
      <p>
        The type color effect is inspired by{' '}
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="http://www.artofthetitle.com/title/enter-the-void"
        >
          the credits in Enter The Void
        </a>
        , and the video was originally created for another project, and inspired
        by{' '}
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.youtube.com/watch?v=Ejr9KBQzQPM"
        >
          Andy Warhol eating a burger
        </a>
        .
      </p>
    </Text>
  </Layout>
);

export default XXX;
